import PropTypes from 'prop-types';
import React from 'react';

const HomePage500Error = ({ error }) => {

    const heroBackgroundImage = {
        background: 'url(//www.autotrader.com/resources/img/homepage/Hero_static_1300.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '475px',
    };

    const h1Styles = {
        color: 'white',
        margin: '0',
    };

    const form = {
        'padding-left': '0px',
    };

    return (
        <>
            <div style={heroBackgroundImage}>
                <div className="margin-horizontal-2 row padding-top-3">
                    <div className="fyc-form col-xs-10 col-lg-8 col-xs-offset-1 col-lg-offset-2">
                        <div className="row">
                            <div className="col-xs-12 col-sm-9">
                                <h1
                                    style={h1Styles}
                                    className="text-size-700 text-light display-inline text-nowrap"
                                >
                                    Cars for Sale - Search Near You
                                </h1>
                            </div>
                            <div
                                className="col-xs-12 col-sm-3 margin-vertical-4 margin-bottom-0 text-nowrap inverted text-right"
                            >
                                <a href="/cars-for-sale/">More search options</a>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                style={form}
                                className="margin-top-3"
                            >
                                <div className="form-group">
                                    <form action="cars-for-sale/searchresults.xhtml">
                                        <div className="col-xs-6 col-sm-4">
                                            <select
                                                className="form-control"
                                                name="makeCodeList"
                                                id="makeCodeList"
                                            >
                                                <option
                                                    selected=""
                                                    value=""
                                                >
                                                    Any Make
                                                </option>
                                                <option value="ACURA">Acura</option>
                                                <option value="ALFA">Alfa Romeo</option>
                                                <option value="AMC">AMC</option>
                                                <option value="ASTON">Aston Martin</option>
                                                <option value="AUDI">Audi</option>
                                                <option value="BENTL">Bentley</option>
                                                <option value="BMW">BMW</option>
                                                <option value="BUGATTI">Bugatti</option>
                                                <option value="BUICK">Buick</option>
                                                <option value="CAD">Cadillac</option>
                                                <option value="CHEV">Chevrolet</option>
                                                <option value="CHRY">Chrysler</option>
                                                <option value="DAEW">Daewoo</option>
                                                <option value="DATSUN">Datsun</option>
                                                <option value="DELOREAN">DeLorean</option>
                                                <option value="DODGE">Dodge</option>
                                                <option value="EAGLE">Eagle</option>
                                                <option value="FER">Ferrari</option>
                                                <option value="FIAT">FIAT</option>
                                                <option value="FISK">Fisker</option>
                                                <option value="FORD">Ford</option>
                                                <option value="FREIGHT">Freightliner</option>
                                                <option value="GENESIS">Genesis</option>
                                                <option value="GEO">Geo</option>
                                                <option value="GMC">GMC</option>
                                                <option value="HONDA">Honda</option>
                                                <option value="AMGEN">HUMMER</option>
                                                <option value="HYUND">Hyundai</option>
                                                <option value="INFIN">INFINITI</option>
                                                <option value="ISU">Isuzu</option>
                                                <option value="JAG">Jaguar</option>
                                                <option value="JEEP">Jeep</option>
                                                <option value="KARMA">Karma</option>
                                                <option value="KIA">Kia</option>
                                                <option value="LAM">Lamborghini</option>
                                                <option value="ROV">Land Rover</option>
                                                <option value="LEXUS">Lexus</option>
                                                <option value="LINC">Lincoln</option>
                                                <option value="LOTUS">Lotus</option>
                                                <option value="MAS">Maserati</option>
                                                <option value="MAYBACH">Maybach</option>
                                                <option value="MAZDA">MAZDA</option>
                                                <option value="MCLAREN">McLaren</option>
                                                <option value="MB">Mercedes-Benz</option>
                                                <option value="MERC">Mercury</option>
                                                <option value="MINI">MINI</option>
                                                <option value="MIT">Mitsubishi</option>
                                                <option value="NISSAN">Nissan</option>
                                                <option value="OLDS">Oldsmobile</option>
                                                <option value="PLYM">Plymouth</option>
                                                <option value="PONT">Pontiac</option>
                                                <option value="POR">Porsche</option>
                                                <option value="RAM">RAM</option>
                                                <option value="RR">Rolls-Royce</option>
                                                <option value="SAAB">Saab</option>
                                                <option value="SATURN">Saturn</option>
                                                <option value="SCION">Scion</option>
                                                <option value="SMART">smart</option>
                                                <option value="SRT">SRT</option>
                                                <option value="SUB">Subaru</option>
                                                <option value="SUZUKI">Suzuki</option>
                                                <option value="TESLA">Tesla</option>
                                                <option value="TOYOTA">Toyota</option>
                                                <option value="VOLKS">Volkswagen</option>
                                                <option value="VOLVO">Volvo</option>
                                                <option value="YUGO">Yugo</option>
                                            </select>
                                        </div>
                                        <div className="col-xs-6 col-sm-2">
                                            <input
                                                href="#"
                                                className="btn btn-primary btn-block"
                                                type="submit"
                                                value="Search"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                { process.env.ENVIRONMENT !== 'prod' && (
                    <pre><code>{error}</code></pre>
                )}
            </div>
        </>
    );

};

HomePage500Error.propTypes = {
    error: PropTypes.string,
};

export default HomePage500Error;
